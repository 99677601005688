import { ModalContainer } from 'components/Upsell/styles/ModalContainer';
import {
    Button,
    CancelButton,
    ModalContent,
    ScrollableContent,
    Title
} from './styles';

interface ConfirmModalProps {
    message: string;
    description?: string;
    onConfirm: (value: boolean) => void;
    confirmButtonText?: string;
    cancelButtonText?: string;
    content?: React.ReactNode;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({ message, description, onConfirm, content, confirmButtonText, cancelButtonText }) => {
    return (
        <ModalContainer>
            <ModalContent>
                <Title>{message}</Title>
                {description && <p>{description}</p>}
                <ScrollableContent>
                    {content}
                </ScrollableContent>
                <CancelButton onClick={() => onConfirm(false)}>{cancelButtonText || 'Não'}</CancelButton>
                <Button onClick={() => onConfirm(true)}>{confirmButtonText || 'Sim'}</Button>
            </ModalContent>
        </ModalContainer>
    );
};

export default ConfirmModal;
