import { ReactNode } from 'react';

import { BumpsProps } from './Bumps';
import { ElementsProps } from './Elements';
import { PluginsProps } from './Plugins';
import { UpsellProps } from './Upsell';

export enum OfferTypeOfCharge {
	RECURRING = 'recurring',
	ONE_TIME = 'one_time',
}

export interface OfferProps {
	is_subscription: boolean;
	is_active: boolean;
	type_of_charge: OfferTypeOfCharge;
	id: number;
	name: string;
	code: string;
	price: number;
	original_price: number;
	description: string;
	product_checkout_description: string;
	allow_credit_card: boolean;
	allow_credit_card_installments: boolean;
	allow_two_credit_cards: boolean;
	allow_pix: boolean;
	allow_bank_slip: boolean;
	allow_bank_slip_installments: boolean;
	credit_card_installments_options: number[];
	bank_slip_installments_limit: number;
	product_name: string;
	product_id: number;
	producer: string;
	subscription: {
		interval: number;
		trial_days: number;
		first_charge_price: number;
		next_charge_price?: number;
		is_extension: boolean;
		extension_length: number;
	};
	builder: {
		uuid: string;
		product_id: number;
		name: string;
		request_email_confirmation: boolean;
		request_address: boolean;
		request_address_after_purchase: boolean;
		default_thankyou_url: string;
		one_page_checkout: boolean;
		active_foreign_purchase: boolean;
		back_redirect_url: string;
		offers: {
			id: number;
			name: string;
		}[];
		theme_settings?: {
			background: {
				type: BackgroundTypes;
				color: string;
				image: string;
				repeat: boolean;
			};
		};
		elements?: ElementsProps[];
		plugins?: PluginsProps[];
		bumps?: BumpsProps[];
		upsell?: UpsellProps;
		is_active: boolean;
	};
}

export interface BannerProps {
	url: string;
}

export enum BackgroundTypes {
	color = 'color',
	image = 'image',
}

export enum AvailableValidations {
	needEmailConfirmation = 'needEmailConfirmation',
	addressSchema = 'addressSchema',
	cardSchema = 'cardSchema',
	doubleCardSchema = 'doubleCardSchema',
}

export enum PaymentMethods {
	CARD = 'credit_card',
	DOUBLE_CARD = 'two_credit_cards',
	PIX = 'pix',
	BANK_SLIP = 'bank_slip',
	PAYPAL = 'paypal',
	APPLE_PAY = 'applePay',
	GOOGLE_PAY = 'googlePay',
	SAMSUNG_PAY = 'samsungPay',
}

export type PaymentMethodsProps = {
	name: string;
	content: any;
	value: PaymentMethods;
};

export interface OneClickProviderProps {
	children: ReactNode;
}

export interface ThemeSettingsProps {
	background: {
		image: string | null;
		type: BackgroundTypes;
		color: string;
		repeat: any;
	};
}

export interface InstallmentsProps {
	amount_with_interest: number;
	installment_values: {}[];
	installments: number;
	labels: {
		label: string;
		value: number;
		quantity: number;
	}[];
}
