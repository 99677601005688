import styled from "styled-components";

export const ModalContent = styled.div`
  background-color: white;
  padding: 1rem;
  border-radius: 8px;
  text-align: center;
  width: 100%;
  max-width: 500px;
  height: auto;
  min-height: 250px;
  max-height: 300px; 
`;

export const Title = styled.h2`
  font-size: 1.2rem;
  margin-bottom: 1rem;
`;

export const ScrollableContent = styled.div`
  overflow-y: auto;
  height: auto;
  max-height: 100px;
`;

export const Button = styled.button`
  margin: 10px;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  background-color: var(--ticto-green);
  color: white;
  transition: filter 0.2s;

  &:hover {
     filter: brightness(0.9);
  }
`;

export const CancelButton = styled(Button)`
  background-color: transparent;
  border: 1px solid var(--ticto-red);
  color: var(--ticto-red); 
`;