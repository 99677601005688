export class HasActiveSubscriptionsError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'HasActiveSubscriptionsError';
    }
}

export class HasCanceledSubscriptionsError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'HasCanceledSubscriptionsError';
    }
}

export class UnknownSubscriptionError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'UnknownSubscriptionError';
    }
}
