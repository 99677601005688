import { api } from "../api";

export const SubscriptionService = {
    async validate(offer_codes: string[], customer: {
        email: string;
        cpf?: string;
        cnpj?: string;
    }) {
        return api.post('find-subscriptions', {
            offer_codes,
            ...customer,
        });
    },
};
